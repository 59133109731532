<template>
  <div>
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="changeLockAction"
          :disabled="loading"
          :loading="loading"
        >
          <font-awesome-icon icon="check" />
        </div>
      </template>
      <span>Annuler l'appel à paiement</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    className: { default: '' },
    dataToUse: { required: true }
  },
  data() {
    return {
      loading: false,
      error: null
    };
  },
  computed: {},
  methods: {
    ...mapActions(['changeLockAppelPaiementCommerciauxTh']),
    async changeLockAction() {
      this.loading = true;
      await this.changeLockAppelPaiementCommerciauxTh(this.dataToUse);
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.spinner-border {
  height: 15px;
  width: 15px;
}
</style>
